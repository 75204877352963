import { PrinterApprovalStatus } from '@core/enums/project';
import { Api, States } from '@core/types';
import useConstructor from '@hooks/useConstructor';
import { projectActionCreators } from '@redux/projects';
import { usePassportContext } from '@tti/passport';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CreateProject from './CreateProject';
import PDFRow from './PDFRow';
import ProjectRow from './ProjectRow';

interface IProps {
  project: States.IProjectState;
  fetchProjects: (params: Api.IFetchProjectsRequest) => void;
  fetchUserPdfs: (params: Api.IFetchUserPdfsRequest) => void;
}

const ProjectList: FunctionComponent<IProps> = ({ project, fetchProjects, fetchUserPdfs }) => {
  const [showCreateProject, setShowCreateProject] = useState(false);
  const { passportContext } = usePassportContext();
  const [tab, setTab] = useState<number>(0);
  useConstructor(() => fetchProjects({ bearerToken: passportContext.bearerToken || '' }));
  useConstructor(() => fetchUserPdfs({ bearerToken: passportContext.bearerToken || '', status: PrinterApprovalStatus.Approved }));
  useConstructor(() => fetchUserPdfs({ bearerToken: passportContext.bearerToken || '', status: PrinterApprovalStatus.Rejected }));

  if (!project) {
    return null;
  }

  const renderPlaceholderContent = () => {
    const previousProjects = project.projects.length > 0;
    const approvedPdfs = project.approvedPDFs.pdfs.length > 0;
    const rejectedPdfs = project.rejectedPDFs.pdfs.length > 0;

    if ((tab === 0 && previousProjects) || (tab === 1 && approvedPdfs) || (tab === 2 && rejectedPdfs)) {
      return;
    }

    let title = 'You have no projects';
    let text = 'Click the button below to start a new project. Any previous projects will appear in this list moving forward.';

    if (tab === 1 && !approvedPdfs) {
      title = 'You have no approved pdfs';
      text = 'Any approved PDFs will appear in this list moving forward.';
    }

    if (tab === 2 && !rejectedPdfs) {
      title = 'You have no rejected pdfs';
      text = 'Any rejected PDFs will appear in this list moving forward.';
    }

    return (
      <div className="no-projects">
        <h2 className="no-projects__title">{title}</h2>
        <div className="no-projects__text">{text}</div>
        {tab === 0 && !previousProjects && (
          <div className="tabs__create">
            <button className="btn btn--inline" onClick={() => setShowCreateProject(true)}>
              Create New Project
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <div className="tabs">
        <div className="tabs__container">
          <button className={`tab ${tab === 0 ? 'active' : ''}`} onClick={() => setTab(0)}>
            Previous Projects
          </button>
          <button className={`tab ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>
            Approved PDFs
          </button>
          <button className={`tab ${tab === 2 ? 'active' : ''}`} onClick={() => setTab(2)}>
            Rejected PDFs
          </button>
        </div>
        {project.projects.length > 0 && (
          <div className="tabs__create">
            <button className="btn btn--inline" onClick={() => setShowCreateProject(true)}>
              Create New Project
            </button>
          </div>
        )}
      </div>
      <CreateProject visible={showCreateProject} setIsVisible={setShowCreateProject} />
      <div className="projects">
        {renderPlaceholderContent()}
        {tab === 0 && project.projects.length > 0 && (
          <TransitionGroup>
            {project.projects.map(x => (
              <CSSTransition key={x.projectGUID} classNames="fade-in" timeout={300}>
                <ProjectRow key={x.projectGUID} project={x} />
              </CSSTransition>
            ))}
          </TransitionGroup>
        )}
        {tab === 1 && (
          <TransitionGroup>
            {project.approvedPDFs.pdfs.map(x => {
              let description = '';

              if (x.printerAppovalStatusUpdatedByUser !== null) {
                description = `Approved By: ${x.printerAppovalStatusUpdatedByUser.userFirstName} ${x.printerAppovalStatusUpdatedByUser.userLastName}`;
              }

              return (
                <CSSTransition key={x.pdfUrl} classNames="fade-in" timeout={300}>
                  <PDFRow
                    key={x.pdfUrl}
                    title={x.project.projectName}
                    url={x.pdfUrl}
                    date={`Created ${x.createdAtISO}`}
                    description={description}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        )}
        {tab === 2 && (
          <TransitionGroup>
            {project.rejectedPDFs.pdfs.map(x => {
              let description = '';

              if (x.printerAppovalStatusUpdatedByUser !== null) {
                description = `Rejected By: ${x.printerAppovalStatusUpdatedByUser.userFirstName} ${x.printerAppovalStatusUpdatedByUser.userLastName}`;
              }

              return (
                <CSSTransition key={x.pdfUrl} classNames="fade-in" timeout={300}>
                  <PDFRow
                    key={x.pdfUrl}
                    title={x.project.projectName}
                    url={x.pdfUrl}
                    date={`Created ${x.createdAtISO}`}
                    description={description}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
});

const mapDispatchToProps = {
  fetchProjects: (params: Api.IBaseAuthorisedRequest) => projectActionCreators.fetchProjects(params),
  fetchUserPdfs: (params: Api.IFetchUserPdfsRequest) => projectActionCreators.fetchUserPdfs(params),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectList);
