import React, { FunctionComponent } from 'react';
import ClassNames from 'classnames';

interface ProductImportProps {
  active: boolean;
}

const ProductImport: FunctionComponent<ProductImportProps> = ({ active }) => {
  const productImportClassNames = ClassNames({
    'product-import': true,
    'is-active': active,
  });

  return (
    <div className={productImportClassNames}>
      <div className="product-import__container">
        <div className="product-import__title">Paste in your article numbers</div>
        <div className="product-import__import-container">
          <textarea id="txtImport" className="product-import__import" placeholder="Paste article numbers one per row"></textarea>
        </div>
      </div>
      <div className="product-import__button">
        <button className="btn">Add Products</button>
      </div>
    </div>
  );
};

export default ProductImport;
