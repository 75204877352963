import React, { FunctionComponent, Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { States, Api } from '@core/types';
import ApprovalRow from './ApprovalRow';
import { adminActionCreators } from '@redux/admin';
import { PassportEnums } from '@tti/passport';
import { usePassportContext } from '@tti/passport';
import { ProjectEnums } from '@core/enums';
import { ProjectPDF } from '@core/types/models';

interface IProps {
  admin?: States.IAdminState;
  fetchPdfs: (params: Api.IFetchPdfsRequest) => void;
}

interface PdfsToRender {
  isLoading: boolean;
  pdfs: ProjectPDF[];
  noProjectsTitle: string;
  noProjectsText: string;
}

const PendingApprovals: FunctionComponent<IProps> = ({ admin, fetchPdfs }) => {
  const { passportContext, getClaims } = usePassportContext();
  const [status, setStatus] = useState<ProjectEnums.PrinterApprovalStatus>(ProjectEnums.PrinterApprovalStatus.AwaitingApproval);
  const [pdfsToRender, setPdfsToRender] = useState<PdfsToRender | null>(null);

  const approvalClaims = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).filter(x =>
    x.value.startsWith(PassportEnums.RoleType.SentToPrintApprover),
  );

  const reportingClaims = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).filter(x =>
    x.value.startsWith(PassportEnums.RoleType.ReportingAccess),
  );

  useEffect(() => {
    if (!passportContext.bearerToken || (approvalClaims.length === 0 && reportingClaims.length === 0)) {
      return;
    }

    const cultureCode = approvalClaims.map(x => {
      // example: role.ftg.sent-to-print-approver.en-gb
      // Get the last index of '.'
      const index = x.value.lastIndexOf('.');

      // + 1 to skip the last '.' and return the culture code
      return x.value.substring(index + 1, x.value.length);
    });

    fetchPdfs({
      bearerToken: passportContext.bearerToken,
      cultureCode,
      status,
    });
  }, [status]);

  useEffect(() => {
    if (!admin) {
      return;
    }

    switch (status) {
      case ProjectEnums.PrinterApprovalStatus.AwaitingApproval:
        setPdfsToRender({...admin.pendingPdfs,
          noProjectsTitle: 'No pending approvals',
          noProjectsText: 'There are no pending approvals at this time.'
        });
        break;
      case ProjectEnums.PrinterApprovalStatus.Approved:
        setPdfsToRender({
          ...admin.approvedPdfs,
          noProjectsTitle: 'There are no approved PDFs',
          noProjectsText: 'Any approved PDFs will appear in this list moving forward.'
        });
        break;
      case ProjectEnums.PrinterApprovalStatus.Rejected:
        setPdfsToRender({
          ...admin.rejectedPdfs,
          noProjectsTitle: 'There are no rejected PDFs',
          noProjectsText: 'Any rejected PDFs will appear in this list moving forward.'});
        break;
    }
  }, [admin, status]);

  return (
    <Fragment>
      <div className="tabs">
        <div className="tabs__container">
          <button
            className={`tab ${status === ProjectEnums.PrinterApprovalStatus.AwaitingApproval ? 'active' : ''}`}
            onClick={() => setStatus(ProjectEnums.PrinterApprovalStatus.AwaitingApproval)}
            disabled={pdfsToRender?.isLoading}
          >
            Pending
          </button>
          <button
            className={`tab ${status === ProjectEnums.PrinterApprovalStatus.Approved ? 'active' : ''}`}
            onClick={() => setStatus(ProjectEnums.PrinterApprovalStatus.Approved)}
            disabled={pdfsToRender?.isLoading}
          >
            Approved
          </button>
          <button
            className={`tab ${status === ProjectEnums.PrinterApprovalStatus.Rejected ? 'active' : ''}`}
            onClick={() => setStatus(ProjectEnums.PrinterApprovalStatus.Rejected)}
            disabled={pdfsToRender?.isLoading}
          >
            Rejected
          </button>
        </div>
        <div className="tabs__create"></div>
      </div>
      <div className="projects">
        
        {!pdfsToRender || pdfsToRender?.isLoading ? (
          <div className="loading-projects">
            <span>Loading...</span>
          </div>
        ) : (
          <>

            {pdfsToRender.pdfs.length === 0 ? (
              <div className="no-projects">
                <h2 className="no-projects__title">{pdfsToRender.noProjectsTitle}</h2>
                <div className="no-projects__text">{pdfsToRender.noProjectsText}</div>
              </div>
            ) : (
              pdfsToRender.pdfs.map(x => <ApprovalRow key={x.projectPDFID} project={x} status={status} />)
            )}
          </>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  admin: state.admin,
});

const mapDispatchToProps = {
  fetchPdfs: (params: Api.IFetchPdfsRequest) => adminActionCreators.fetchPdfs(params),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingApprovals);
