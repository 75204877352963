import { Navigation, ProductImport, ProductSearch, ProductSelect } from '@components/navigation';
import { ProjectDetail } from '@components/projects';
import { AppEnums } from '@core/enums';
import { Api, Models, States } from '@core/types';
import useConstructor from '@hooks/useConstructor';
import { appActionCreators } from '@redux/app';
import { projectActionCreators } from '@redux/projects';
import { usePassportContext } from '@tti/passport';
import React, { Fragment, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

interface IProps {
  project?: States.IProjectState;
  product?: States.IProductState;
  fetchProject: (params: Api.IFetchProjectRequest) => void;
  fetchProjectPdf: (params: Api.IFetchProjectPdfRequest) => void;
  setNavigation: (navigation: string) => void;
}

const Project: FunctionComponent<IProps> = ({ project, product, fetchProject, fetchProjectPdf, setNavigation }) => {
  const { passportContext } = usePassportContext();
  const { projectGUID, projectPDFID } = useParams<{ projectGUID: string; projectPDFID: string }>();

  useConstructor(() => {
    fetchProject({
      bearerToken: passportContext.bearerToken || '',
      projectGUID: projectGUID || '',
    });

    if (projectPDFID) {
      fetchProjectPdf({
        bearerToken: passportContext.bearerToken || '',
        projectPDFID: parseInt(projectPDFID, 10),
      });
    }
  });

  const renderCategory = (category: Models.Category | null, navigationID: AppEnums.Navigation) => {
    if (category === null) {
      return null;
    }

    return <ProductSelect category={category} navigationID={navigationID} onClose={handleClick} />;
  };

  const handleClick = () => {
    setNavigation(AppEnums.Navigation.None);
  };

  return (
    <Fragment>
      <Navigation />
      <main onClick={handleClick}>
        <h1 className="ftg-heading">Fact Tag Generator</h1>
        {product && projectGUID && project && project.currentProject && (
          <Fragment>
            <div className="site-wide">
              <ProjectDetail />
            </div>

            {renderCategory(product.handTools, AppEnums.Navigation.HandTools)}
            {renderCategory(product.storage, AppEnums.Navigation.Storage)}
            {renderCategory(product.powerTools, AppEnums.Navigation.Corded)}
            {renderCategory(product.m18, AppEnums.Navigation.M18)}
            {renderCategory(product.m12, AppEnums.Navigation.M12)}
            {renderCategory(product.mxFuel, AppEnums.Navigation.MXFuel)}

            <ProductImport />
            <ProductSearch />
          </Fragment>
        )}
      </main>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
  product: state.product,
});

const mapDispatchToProps = {
  fetchProject: (params: Api.IFetchProjectRequest) => projectActionCreators.fetchProject(params),
  fetchProjectPdf: (params: Api.IFetchProjectPdfRequest) => projectActionCreators.fetchProjectPdf(params),
  setNavigation: (navigation: string) => appActionCreators.setActiveNav(navigation),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Project);
