import React, { Fragment, FunctionComponent, useState } from 'react';
import { Dropdown } from '@components/shared';
import { Loader } from '@components/loaders';
import { States } from '@core/types';
import { ProjectEnums } from '@core/enums';
import classNames from 'classnames';
import Checkbox from '@components/shared/Checkbox';
import { t } from '@components/translations';
import ProjectDetailsForm from './ProjectDetailsForm';

interface IProps {
  app: States.IAppState;
  project: States.IProjectState;
  onSelectedSettings: (
    cultureCode: string,
    size: ProjectEnums.PDFSize,
    type: ProjectEnums.PDFType,
    headerLayout: ProjectEnums.PDFHeaderLayout,
  ) => void;
  onSubmit: () => void;
  setOrderDetails: (formData: Record<string, string> | null) => void;
}

const PdfPrintSettings: FunctionComponent<IProps> = ({ app, project, onSubmit, onSelectedSettings, setOrderDetails }) => {
  const [hasViewed, setHasViewed] = useState(false);
  const [cultureCode, setCultureCode] = useState<string>('');
  const [headerLayout, setHeaderLayout] = useState<ProjectEnums.PDFHeaderLayout>(ProjectEnums.PDFHeaderLayout.Normal);
  const [hasSubmitForm, setHasSubmitForm] = useState(false);

  const handleSubmitInternal = () => {
    if (cultureCode === '') {
      return;
    }

    setHasViewed(false);

    onSelectedSettings(cultureCode, ProjectEnums.PDFSize.Print, ProjectEnums.PDFType.SendToPrinters, headerLayout);
  };

  const handleSubmitForm = (formData: Record<string, string>) => {
    setOrderDetails(formData);
  };

  const handleSendForApproval = () => {
    if (!hasViewed || project.printPDF.modal.isSendingForApproval) {
      return;
    }

    onSubmit();
  };

  const renderGenerate = () => {
    const buttonStyles = classNames({
      btn: true,
      'btn--disabled': cultureCode === '',
    });

    const dropdownCultures = app.cultures
      .map(x => ({
        key: x.cultureCode,
        value: t(x.cultureName),
      }))
      .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

    if (project.printPDF.modal.orderDetails === null) {
      return (
        <ProjectDetailsForm
          internationalPhoneNumberPrefixes={app.internationalPhoneNumberPrefixes}
          formData={project.printPDF.modal.orderDetails}
          hasSubmit={hasSubmitForm}
          countries={app.countries}
          invoiceCountries={app.invoiceCountries}
          onSubmit={handleSubmitForm}
        />
      );
    }

    return (
      <Fragment>
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Choose Your Language</h2>
            <p className="align--center">
              Select which language you’d like your fact tags to be generated in and click download to generate your PDF.
            </p>
            <Dropdown
              placeholder="Select Language..."
              onChange={(value: string) => setCultureCode(value)}
              value={cultureCode}
              values={dropdownCultures}
            />

            <Checkbox
              label="Export with Milwaukee&reg; Logo"
              value={headerLayout === ProjectEnums.PDFHeaderLayout.Normal}
              onClick={() => {
                if (headerLayout === ProjectEnums.PDFHeaderLayout.Normal) {
                  setHeaderLayout(ProjectEnums.PDFHeaderLayout.NoLogo);
                } else {
                  setHeaderLayout(ProjectEnums.PDFHeaderLayout.Normal);
                }
              }}
            />
          </div>
        </div>
        <button className={buttonStyles} onClick={handleSubmitInternal} type="button">
          Create PDF
        </button>
      </Fragment>
    );
  };

  if (project.printPDF.modal.showConfirmation) {
    return (
      <Fragment>
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">PDF sent</h2>
            <p className="align--center">Your request has been sent to the print team for approval</p>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {project.printPDF.projectPdf === null && !project.printPDF.modal.isGenerating && renderGenerate()}

      {project.printPDF.modal.isGenerating && (
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Generating PDF</h2>
            <p className="align--center" dangerouslySetInnerHTML={{ __html: t('ftg.generatepdf.sendtoprinters') }}></p>
            <Loader />
          </div>
        </div>
      )}
      {project.printPDF.projectPdf && !project.printPDF.modal.isGenerating && (
        <Fragment>
          <div className="form__inner">
            <div className="form__fields">
              <h2 className="align--center">Review your PDF</h2>
              <p className="align--center">You must view your PDF before sending to the printers</p>
              <p className="align--center">
                Click{' '}
                <a
                  href={project.printPDF.projectPdf.pdfUrl}
                  onClick={() => setHasViewed(true)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>{' '}
                to review your PDF
              </p>
            </div>
          </div>
          <button
            className={`btn ${!hasViewed || project.printPDF.modal.isSendingForApproval ? 'btn--disabled' : ''}`}
            onClick={handleSendForApproval}
            type="button"
          >
            Send To Printers
          </button>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PdfPrintSettings;
