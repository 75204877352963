import { ProductRow } from '@components/products';
import { AppEnums } from '@core/enums';
import { Api, Models, States } from '@core/types';
import m12 from '@img/m12.png';
import m18 from '@img/m18.png';
import mxFuel from '@img/MXFuel.png';
import { appActionCreators } from '@redux/app';
import { projectActionCreators } from '@redux/projects';
import { PassportEnums, usePassportContext } from '@tti/passport';
import ClassNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import Simplebar from 'simplebar-react';

interface IProps {
  app?: States.IAppState;
  project?: States.IProjectState;
  category: Models.Category | null;
  updateProject: (params: Api.IUpdateProjectRequest) => void;
  setActiveNav: (navigation: string) => void;
  navigationID: AppEnums.Navigation;
  onClose: () => void;
}

const ProductSelect: FunctionComponent<IProps> = ({ app, project, category, updateProject, navigationID, onClose }) => {
  const { passportContext, getClaim } = usePassportContext();
  const cultureClaim = getClaim(PassportEnums.ClaimType.Locality, passportContext.claims);
  const [collapsedIds, setCollapsedIds] = useState<number[]>([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);

  if (!app || !category || !project || project.currentProject === null) {
    return null;
  }

  const productSelectClassNames = ClassNames({
    'product-select': true,
    'is-active': app.activeNavigation === navigationID,
  });

  const handleAdd = (agilityIds: number[], removeIfExisting = true, removeAll?: boolean): void => {
    if (!project.currentProject || !passportContext.bearerToken || !cultureClaim) {
      return;
    }

    const projectGUID = project.currentProject.projectGUID;

    let projectProducts: Models.ProjectProduct[] = [];

    // Add the existing products
    for (const product of project.currentProject.products) {
      projectProducts.push({
        productID: product.productID,
        quantity: product.quantity,
      });
    }

    // Loop through all the products from the given list
    for (const agilityId of agilityIds) {
      // Get the index in the current project
      const existingProductIndex = project.currentProject.products.findIndex(x => x.productID === agilityId);

      if (removeAll) {
        // remove all products matching ID
        projectProducts = projectProducts.filter(x => !agilityIds.includes(x.productID));
      } else {
        // add them in if they aren't already in there
        if (existingProductIndex === -1) {
          projectProducts.push({
            productID: agilityId,
            quantity: 1,
          });
        } else if (removeIfExisting) {
          // else we want to remove it
          projectProducts.splice(existingProductIndex, 1);
        }
      }
    }

    // Add all the products
    updateProject({
      projectGUID,
      projectProducts,
      bearerToken: passportContext.bearerToken,
      cultureCode: 'en-TT',
      refetchProject: true,
    });
  };

  const handleCollapse = (id: number): void => {
    if (collapsedIds.includes(id)) {
      // remove from state
      const newArray = collapsedIds.filter(x => x !== id);

      // replace state with new filtered array
      setCollapsedIds(newArray);
    } else {
      // add to state
      setCollapsedIds([...collapsedIds, id]);
    }
  };

  const renderCategoryTitle = (category: Models.Category | null) => {
    if (category === null) {
      return null;
    }

    let title = <>{category.categoryName}</>;

    if (navigationID === AppEnums.Navigation.M12) {
      title = <img alt="M12" src={m12} />;
    }

    if (navigationID === AppEnums.Navigation.M18) {
      title = <img alt="M18" src={m18} />;
    }

    if (navigationID === AppEnums.Navigation.MXFuel) {
      title = <img alt="MX Fuel" src={mxFuel} />;
    }
    return <div className="product-select__category-title">{title}</div>;
  };

  return (
    <div className={productSelectClassNames} onClick={e => e.stopPropagation()}>
      <div className="product-select__close" onClick={onClose}>
        Close
      </div>
      <Simplebar className="product-select__container">
        <div className="product-select__select-all">
          {renderCategoryTitle(category)}
          <button
            type="button"
            className="btn btn--small btn--inline"
            onClick={() => {
              const products = category.childCategories.flatMap(c => c.childProducts);
              handleAdd(products.map(x => x.variantAgilityID), false);
              setSelectedCategoryIds(category.childCategories.flatMap(c => c.categoryAgilityID));
            }}
          >
            Select all
          </button>
        </div>

        {app.activeNavigation === navigationID &&
          category.childCategories.map(c => (
            <div
              key={c.categoryName}
              className="product-select__sub-category-container"
              aria-current={!collapsedIds.includes(c.categoryAgilityID)}
            >
              <div className="product-select__sub-category">
                {c.categoryName}
                <div>
                  <div className="selected-products__select-all">
                    <input
                      id={`selectedAll${c.categoryAgilityID}chkbox`}
                      type="checkbox"
                      className="selected-products__select-all--input"
                      onChange={() => {
                        const isCategoryActive = selectedCategoryIds.includes(c.categoryAgilityID);

                        if (!isCategoryActive) {
                          setSelectedCategoryIds([...selectedCategoryIds, c.categoryAgilityID]);
                        } else {
                          setSelectedCategoryIds(selectedCategoryIds.filter(x => x !== c.categoryAgilityID));
                        }

                        handleAdd(c.childProducts.map(x => x.variantAgilityID), false, isCategoryActive);
                      }}
                      checked={selectedCategoryIds.includes(c.categoryAgilityID)}
                    />
                    <label htmlFor={`selectedAll${c.categoryAgilityID}chkbox`} className="selected-products__select-all--label">
                      Select All
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn btn--small btn--inline btn--black btn--collapse"
                    onClick={() => handleCollapse(c.categoryAgilityID)}
                  >
                    <span>Collapse Section</span>
                    <span>Expand Section</span>
                  </button>
                </div>
              </div>
              <div className="product-select__products">
                {c.childProducts
                  .sort((a, b) => (a.variantName > b.variantName ? 1 : -1))
                  .map(p => {
                    if (project.currentProject === null) {
                      return null;
                    }

                    const isSelected = project.currentProject.products.findIndex(x => x.productID === p.variantAgilityID) > -1;

                    return (
                      <ProductRow
                        key={p.variantAgilityID}
                        isSelected={isSelected}
                        title={p.variantName}
                        productAgilityId={p.variantAgilityID}
                        onChange={(e: number) => handleAdd([e])}
                        isHalf={true}
                      />
                    );
                  })}
              </div>
            </div>
          ))}
      </Simplebar>
    </div>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
  app: state.app,
});

const mapDispatchToProps = {
  updateProject: (params: Api.IUpdateProjectRequest) => projectActionCreators.updateProject(params),
  setActiveNav: (navigation: string) => appActionCreators.setActiveNav(navigation),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductSelect);
