import { Api, Models } from '@core/types';
import { Dispatch, AnyAction } from 'redux';
import {
  FETCH_PENDING_APPROVALS,
  FETCH_PENDING_APPROVALS_ERROR,
  FETCH_PENDING_APPROVALS_SUCCESS,
  PRINTER_APPROVE_REJECT,
  PRINTER_APPROVE_REJECT_ERROR,
  PRINTER_APPROVE_REJECT_SUCCESS,
} from './actions';
import { ApiService } from '@core/services';
import { ThunkDispatch } from 'redux-thunk';
import { ProjectEnums } from '@core/enums';

export const fetchPdfs = (params: Api.IFetchPdfsRequest) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_PENDING_APPROVALS,
    payload: params.status,
  });

  const response = await ApiService.request<Api.IFetchPdfsResponse>({
    controller: 'project',
    method: Api.Method.GET,
    slug: 'list-pdfs',
    bearerToken: params.bearerToken,
    params: {
      cultureCode: params.cultureCode,
      status: params.status,
    },
  });

  if (!response.success || response.errors.length > 0 || !response.data) {
    return dispatch({
      type: FETCH_PENDING_APPROVALS_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the final response data
  return dispatch({
    type: FETCH_PENDING_APPROVALS_SUCCESS,
    payload: {
      projectPDFs: response.data.projectPDFs,
      status: params.status,
    },
  });
};

export const printerApproveReject = (
  params: Api.IPrinterApproveReject,
  cultureCodes: string[],
  status: ProjectEnums.PrinterApprovalStatus,
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: PRINTER_APPROVE_REJECT,
  });

  // Do the api request
  const response = await ApiService.request<boolean>({
    controller: 'project',
    method: Api.Method.POST,
    slug: 'printer-approve-reject',
    bearerToken: params.bearerToken,
    params: {
      projectPDFID: params.projectPDFID,
      acceptReject: params.acceptReject,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: PRINTER_APPROVE_REJECT_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the final response data
  dispatch({
    type: PRINTER_APPROVE_REJECT_SUCCESS,
    payload: response.data,
  });

  // refetch the pending pdfs to update the list
  const pendingApprovalParams: Api.IFetchPdfsRequest = {
    bearerToken: params.bearerToken,
    cultureCode: cultureCodes,
    status,
  };

  return dispatch(fetchPdfs(pendingApprovalParams));
};
