export enum Navigation {
  None = 'NONE',
  Import = 'IMPORT',
  Search = 'SEARCH',
  PowerTools = 'POWER TOOLS',
  HandTools = 'HAND TOOLS',
  Storage = 'STORAGE',
  Corded = 'CORDED',
  M12 = 'M12',
  M18 = 'M18',
  MXFuel = 'MX FUEL',
}
