import React, { Fragment, FunctionComponent, useState } from 'react';
import { Models, Api } from '@core/types';
import { Close, Verified } from '@components/icons';
import { connect } from 'react-redux';
import { adminActionCreators } from '@redux/admin';
import { PassportEnums } from '@tti/passport';
import { usePassportContext } from '@tti/passport';
import { ProjectEnums } from '@core/enums';
import { MdChevronRight } from 'react-icons/md';

interface IProps {
  project: Models.ProjectPDF;
  status: ProjectEnums.PrinterApprovalStatus;
  printerApproveReject: (
    params: Api.IPrinterApproveReject,
    cultureCodes: string[],
    status: ProjectEnums.PrinterApprovalStatus,
  ) => void;
}

const ProjectRow: FunctionComponent<IProps> = ({ project, status, printerApproveReject }) => {
  const { passportContext, getClaim, getClaims } = usePassportContext();
  const cultureClaim = getClaim(PassportEnums.ClaimType.Locality, passportContext.claims);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Get all the send to printer claims
  const approvalClaims = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).filter(x =>
    x.value.startsWith(PassportEnums.RoleType.SentToPrintApprover),
  );

  const cultureCodes = approvalClaims.map(x => {
    // example: role.ftg.sent-to-print-approver.en-gb
    // Get the last index of '.'
    const index = x.value.lastIndexOf('.');

    // + 1 to skip the last '.' and return the culture code
    return x.value.substring(index + 1, x.value.length);
  });

  const handleApproveReject = (acceptReject: boolean) => {
    if (!passportContext.bearerToken || !cultureClaim || disabled) {
      return;
    }

    setDisabled(true);

    printerApproveReject(
      {
        bearerToken: passportContext.bearerToken,
        cultureCode: cultureClaim.value,
        projectPDFID: project.projectPDFID,
        acceptReject,
      },
      cultureCodes,
      status,
    );
  };

  return (
    <div className={`project-item ${disabled ? 'project-item--disabled' : ''}`}>
      <div className="project-item__container">
        <div className="project-item__content">
          <div className="project-item__title">
            <span className={`flag ${project.cultureCode}`}></span>
            <a href={project.pdfUrl} target="_blank" rel="noopener noreferrer">
              {project.pdfUrl}
            </a>
          </div>

          <div className="project-item__fields">
            {project.user && (
              <div className="project-item__field">
                <span className="project-item__field-label">Requested By:</span>
                <span className="project-item__field-value">
                  {project.user.userFirstName} {project.user.userLastName}
                </span>
              </div>
            )}

            {status === ProjectEnums.PrinterApprovalStatus.Approved && (
              <Fragment>
                <div className="project-item__field">
                  <span className="project-item__field-label">Approved By:</span>
                  <span className="project-item__field-value">
                    {project.printerAppovalStatusUpdatedByUser !== null && (
                      <Fragment>
                        {project.printerAppovalStatusUpdatedByUser.userFirstName}{' '}
                        {project.printerAppovalStatusUpdatedByUser.userLastName}
                      </Fragment>
                    )}
                  </span>
                </div>
                <div className="project-item__field">
                  <span className="project-item__field-label">Approved On:</span>
                  <span className="project-item__field-value">
                    {project.printerAppovalStatusUpdatedAtISO ? project.printerAppovalStatusUpdatedAtISO : 'N/A'}
                  </span>
                </div>
              </Fragment>
            )}

            {status === ProjectEnums.PrinterApprovalStatus.Rejected && (
              <Fragment>
                <div className="project-item__field">
                  <span className="project-item__field-label">Rejected By:</span>
                  <span className="project-item__field-value">
                    {project.printerAppovalStatusUpdatedByUser !== null && (
                      <Fragment>
                        {project.printerAppovalStatusUpdatedByUser.userFirstName}{' '}
                        {project.printerAppovalStatusUpdatedByUser.userLastName}
                      </Fragment>
                    )}
                  </span>
                </div>
                <div className="project-item__field">
                  <span className="project-item__field-label">Rejected On:</span>
                  <span className="project-item__field-value">
                    {project.printerAppovalStatusUpdatedAtISO ? project.printerAppovalStatusUpdatedAtISO : 'N/A'}
                  </span>
                </div>
              </Fragment>
            )}

            <div className="project-item__field">
              <span className="project-item__field-label">Submission Date:</span>
              <span className="project-item__field-value">{project.createdAtISO}</span>
            </div>

            <div className="project-item__field">
              <span className="project-item__field-label">Number of Tags:</span>
              <span className="project-item__field-value">{project.totalPages === 0 ? 'N/A' : project.totalPages}</span>
            </div>
            {!isOpen && (
              <button type="button" className="project-item__toggle project-item__toggle--down" onClick={() => setIsOpen(true)}>
                <MdChevronRight />
              </button>
            )}
          </div>

          {isOpen && (
            <div className="project-item__fields">
              <div className="project-item__field">
                <span className="project-item__field-label">Order Number:</span>
                <span className="project-item__field-value">{project.orderNumber}</span>
              </div>

              <div className="project-item__field">
                <span className="project-item__field-label">Company Name:</span>
                <span className="project-item__field-value">{project.orderCompanyName}</span>
              </div>

              <div className="project-item__field">
                <span className="project-item__field-label">First Name:</span>
                <span className="project-item__field-value">{project.orderFirstName}</span>
              </div>

              <div className="project-item__field">
                <span className="project-item__field-label">Last Name:</span>
                <span className="project-item__field-value">{project.orderLastName}</span>
              </div>

              <div className="project-item__field">
                <span className="project-item__field-label">Email Address:</span>
                <span className="project-item__field-value">{project.orderEmailAddress}</span>
              </div>

              <div className="project-item__field">
                <span className="project-item__field-label">Address Line 1:</span>
                <span className="project-item__field-value">{project.orderAddressLine1}</span>
              </div>

              <div className="project-item__field">
                <span className="project-item__field-label">Address Line 2:</span>
                <span className="project-item__field-value">{project.orderAddressLine2}</span>
              </div>

              <div className="project-item__field">
                <span className="project-item__field-label">City:</span>
                <span className="project-item__field-value">{project.orderCity}</span>
              </div>

              <div className="project-item__field">
                <span className="project-item__field-label">Post Code:</span>
                <span className="project-item__field-value">{project.orderPostcode}</span>
              </div>

              <div className="project-item__field">
                <span className="project-item__field-label">Country:</span>
                <span className="project-item__field-value">{project.orderCountry}</span>
              </div>

              <div className="project-item__field">
                <span className="project-item__field-label">Invoiced Country:</span>
                <span className="project-item__field-value">{project.invoicedCountry}</span>
              </div>

              <div className="project-item__field">
                <span className="project-item__field-label">Notes:</span>
                <span className="project-item__field-value">{project.orderNotes}</span>
              </div>

              <button className="project-item__toggle project-item__toggle--up" onClick={() => setIsOpen(false)}>
                <MdChevronRight />
              </button>
            </div>
          )}
        </div>
        {approvalClaims.length > 0 && status === ProjectEnums.PrinterApprovalStatus.AwaitingApproval && (
          <div className="project-item__controls">
            <div className="project-item__button project-item__add" onClick={() => handleApproveReject(true)}>
              <Verified />
              <span>Approve</span>
            </div>
            <div className="project-item__button project-item__remove" onClick={() => handleApproveReject(false)}>
              <Close />
              <span>Reject</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  printerApproveReject: (params: Api.IPrinterApproveReject, cultureCodes: string[], status: ProjectEnums.PrinterApprovalStatus) =>
    adminActionCreators.printerApproveReject(params, cultureCodes, status),
};

export default connect(
  null,
  mapDispatchToProps,
)(ProjectRow);
