import { Models } from '@core/types';

export const SET_NAVIGATION = 'APP/SET_NAVIGATION';

export const FETCH_CULTURES = 'APP/FETCH_CULTURES';
export const FETCH_CULTURES_SUCCESS = 'APP/FETCH_CULTURES_SUCCESS';

export const FETCH_COUNTRIES = 'APP/FETCH_COUNTRIES';
export const FETCH_COUNTRIES_SUCCESS = 'APP/FETCH_COUNTRIES_SUCCESS';

export const FETCH_INVOICE_COUNTRIES = 'APP/FETCH_INVOICE_COUNTRIES';
export const FETCH_INVOICE_COUNTRIES_SUCCESS = 'APP/FETCH_INVOICE_COUNTRIES_SUCCESS';

export const FETCH_INTERNATIONAL_PHONE_NUMBER_PREFIXES = 'APP/FETCH_INTERNATIONAL_PHONE_NUMBER_PREFIXES';
export const FETCH_INTERNATIONAL_PHONE_NUMBER_PREFIXES_SUCCESS = 'APP/FETCH_INTERNATIONAL_PHONE_NUMBER_PREFIXES_SUCCESS';

export interface ISetNavigation {
  type: typeof SET_NAVIGATION;
  payload: string;
}

export interface IFetchCultures {
  type: typeof FETCH_CULTURES;
}

export interface IFetchCulturesSuccess {
  type: typeof FETCH_CULTURES_SUCCESS;
  payload: Models.Culture[];
}

export interface IFetchCountries {
  type: typeof FETCH_COUNTRIES;
}

export interface IFetchCountriesSuccess {
  type: typeof FETCH_COUNTRIES_SUCCESS;
  payload: Models.Country[];
}

export interface IFetchInvoiceCountries {
  type: typeof FETCH_INVOICE_COUNTRIES;
}

export interface IFetchInvoiceCountriesSuccess {
  type: typeof FETCH_INVOICE_COUNTRIES_SUCCESS;
  payload: Models.Country[];
}

export interface IFetchInternationalPhoneNumberPrefixes {
  type: typeof FETCH_INTERNATIONAL_PHONE_NUMBER_PREFIXES;
}

export interface IFetchInternationalPhoneNumberPrefixesSuccess {
  type: typeof FETCH_INTERNATIONAL_PHONE_NUMBER_PREFIXES_SUCCESS;
  payload: Models.CultureNumber[];
}

export type AppActionTypes =
  | ISetNavigation
  | IFetchCultures
  | IFetchCulturesSuccess
  | IFetchCountries
  | IFetchCountriesSuccess
  | IFetchInvoiceCountries
  | IFetchInvoiceCountriesSuccess
  | IFetchInternationalPhoneNumberPrefixes
  | IFetchInternationalPhoneNumberPrefixesSuccess;
