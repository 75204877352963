import { Navigation } from '@components/navigation';
import { ProjectList } from '@components/projects';
import React, { Fragment, FunctionComponent } from 'react';

interface IProps {}

const Landing: FunctionComponent<IProps> = () => (
  <Fragment>
    <Navigation />
    <main>
      <h1 className="ftg-heading">Fact Tag Generator</h1>
      <div className="site-wide">
        <ProjectList />
      </div>
    </main>
  </Fragment>
);

export default Landing;
