import React, { FunctionComponent, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdSearch, MdExitToApp, MdHome, MdInsertChart } from 'react-icons/md';
import { Logo, Import, Verified } from '@components/icons';
import { Routes } from '@components/routing';
import { AppEnums } from '@core/enums';
import { appActionCreators } from '@redux/app';
import { States, Models } from '@core/types';
import { usePassportContext } from '@tti/passport';
import m12 from '@img/m12.png';
import m18 from '@img/m18.png';
import mxFuel from '@img/MXFuel.png';
import { PassportEnums } from '@tti/passport';
import { push } from 'connected-react-router';

interface IProps {
  app: States.IAppState;
  product: States.IProductState;
  project: States.IProjectState;
  admin: States.IAdminState;
  setNavigation: (navigation: string) => void;
  redirect: (path: string, state?: {}) => void;
}

const Navigation: FunctionComponent<IProps> = ({ app, admin, product, project, setNavigation, redirect }) => {
  const { passportContext, getClaims, logOut } = usePassportContext();
  const approvalClaim = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).find(x =>
    x.value.startsWith(PassportEnums.RoleType.SentToPrintApprover),
  );
  const reportingClaim = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).find(x =>
    x.value.startsWith(PassportEnums.RoleType.ReportingAccess),
  );

  const closeNavigation = () => {
    if (app.activeNavigation !== AppEnums.Navigation.None) {
      setNavigation(AppEnums.Navigation.None);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    // Escape
    if (e.keyCode === 27 && app.activeNavigation !== AppEnums.Navigation.None) {
      closeNavigation();
    }
  };

  useEffect(() => {
    window.addEventListener('click', closeNavigation);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('click', closeNavigation);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!app) {
    return null;
  }

  const handleSetNavigation = (e: React.MouseEvent, navigation: string) => {
    if (app.activeNavigation === navigation) {
      setNavigation(AppEnums.Navigation.None);
      return;
    }

    setNavigation(navigation);
  };

  const getItemClasses = (navigationID: AppEnums.Navigation) => {
    let className = 'navigation__text';

    if (app.activeNavigation === navigationID) {
      className += ' active';
    }

    return className;
  };

  const renderCategory = (category: Models.Category | null, navigationID: AppEnums.Navigation) => {
    if (!category) {
      return null;
    }

    let tab = <Fragment>{category.categoryName}</Fragment>;

    if (navigationID === AppEnums.Navigation.M12) {
      tab = <img alt="M12" src={m12} />;
    }

    if (navigationID === AppEnums.Navigation.M18) {
      tab = <img alt="M18" src={m18} />;
    }

    if (navigationID === AppEnums.Navigation.MXFuel) {
      tab = <img alt="MX Fuel" src={mxFuel} />;
    }

    return (
      <div className={getItemClasses(navigationID)} onClick={e => handleSetNavigation(e, navigationID)}>
        {tab}
      </div>
    );
  };

  return (
    <nav className="navigation" onClick={e => e.stopPropagation()}>
      <Link to={Routes.home.path}>
        <div className="navigation-logo">
          <Logo />
        </div>
      </Link>
      <div className="navigation__content">
        <div
          className={`navigation__icon ${window.location.pathname === Routes.home.path ? 'active' : ''}`}
          onClick={() => redirect(Routes.home.path)}
        >
          <MdHome />
        </div>
        {/* Show project related icons when we have a project active */}
        {project.currentProject !== null && (
          <Fragment>
            {renderCategory(product.handTools, AppEnums.Navigation.HandTools)}
            {renderCategory(product.storage, AppEnums.Navigation.Storage)}
            {renderCategory(product.powerTools, AppEnums.Navigation.PowerTools)}
            {renderCategory(product.m18, AppEnums.Navigation.M18)}
            {renderCategory(product.m12, AppEnums.Navigation.M12)}
            {renderCategory(product.mxFuel, AppEnums.Navigation.MXFuel)}
            <div
              className={getItemClasses(AppEnums.Navigation.Import)}
              onClick={e => handleSetNavigation(e, AppEnums.Navigation.Import)}
            >
              <Import className="navigation__text--icon" />
              Import
            </div>
            <div
              className={getItemClasses(AppEnums.Navigation.Search)}
              onClick={e => handleSetNavigation(e, AppEnums.Navigation.Search)}
            >
              <MdSearch className="navigation__text--icon" />
              Search
            </div>
          </Fragment>
        )}
      </div>
      {reportingClaim && (
        <div className="navigation__content">
          <div
            className={`navigation__icon ${window.location.pathname === Routes.reports.path ? 'active' : ''}`}
            onClick={() => redirect(Routes.reports.path)}
          >
            <MdInsertChart />
          </div>
        </div>
      )}
      <div className="navigation__content navigation__content--bottom">
        {/* Show user specific icons based on roles */}
        {(approvalClaim || reportingClaim) && admin && (
          <div
            className={`navigation__icon ${admin.pendingPdfs.pdfs.length > 0 ? 'active' : ''}`}
            onClick={() => redirect(Routes.admin.path)}
          >
            <Verified />
            {admin.pendingPdfs.pdfs.length > 0 && approvalClaim && (
              <div className="navigation__icon-tooltip">{admin.pendingPdfs.pdfs.length}</div>
            )}
          </div>
        )}
        <div className="navigation__icon" onClick={logOut}>
          <MdExitToApp />
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  app: state.app,
  product: state.product,
  project: state.project,
  admin: state.admin,
});

const mapDispatchToProps = {
  setNavigation: (navigation: string) => appActionCreators.setActiveNav(navigation),
  redirect: (path: string, state?: {}) => push(path, state),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Navigation);
