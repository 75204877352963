import { States } from '@core/types';
import {
  AppActionTypes,
  SET_NAVIGATION,
  FETCH_CULTURES,
  FETCH_CULTURES_SUCCESS,
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_INVOICE_COUNTRIES,
  FETCH_INVOICE_COUNTRIES_SUCCESS,
  FETCH_INTERNATIONAL_PHONE_NUMBER_PREFIXES,
  FETCH_INTERNATIONAL_PHONE_NUMBER_PREFIXES_SUCCESS,
} from './actions';
import { AppEnums } from '@core/enums';

const initialState: States.IAppState = {
  activeNavigation: AppEnums.Navigation.None,
  cultures: [],
  isLoadingCultures: false,
  countries: [],
  invoiceCountries: [],
  internationalPhoneNumberPrefixes: [],
};

function appReducer(state: States.IAppState = initialState, action: AppActionTypes): States.IAppState {
  switch (action.type) {
    case SET_NAVIGATION: {
      const newState = { ...state };

      newState.activeNavigation = action.payload;

      return newState;
    }

    case FETCH_CULTURES: {
      const newState = { ...state };

      newState.isLoadingCultures = true;

      return newState;
    }

    case FETCH_CULTURES_SUCCESS: {
      const newState = { ...state };

      newState.isLoadingCultures = false;
      newState.cultures = action.payload;

      return newState;
    }

    case FETCH_COUNTRIES: {
      const newState = { ...state };

      return newState;
    }

    case FETCH_COUNTRIES_SUCCESS: {
      const newState = { ...state };

      newState.countries = action.payload;

      return newState;
    }

    case FETCH_INVOICE_COUNTRIES: {
      const newState = { ...state };

      return newState;
    }

    case FETCH_INVOICE_COUNTRIES_SUCCESS: {
      const newState = { ...state };

      newState.invoiceCountries = action.payload;

      return newState;
    }

    case FETCH_INTERNATIONAL_PHONE_NUMBER_PREFIXES: {
      const newState = { ...state };

      return newState;
    }

    case FETCH_INTERNATIONAL_PHONE_NUMBER_PREFIXES_SUCCESS: {
      const newState = { ...state };

      newState.internationalPhoneNumberPrefixes = action.payload;

      return newState;
    }

    default:
      return state;
  }
}

export default appReducer;
