export enum PrinterApprovalStatus {
  NotSent = 0,
  AwaitingApproval = 1,
  Approved = 2,
  Rejected = 3,
  Generating = 4,
}

export enum PDFType {
  Download = 0,
  SendToPrinters = 1,
}

export enum PDFSize {
  A4 = 0,
  A3 = 1,
  Print = 2,
  ReorderTags = 3,
  Flyer = 4,
  AddressLabel = 5,
  JSSA4 = 6,
  JSSA4Triple = 7,
  JSSA4Double = 8,
  JSS16x9 = 9,
  ReorderTagsTiled = 10,
}

export enum PDFHeaderLayout {
  Normal = 0,
  NoLogo = 1,
}
