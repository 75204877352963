export enum AgilityIDs {
  PowerTools = 301422,
  HandTools = 301426,
  Storage = 476841,
}

export enum SystemIDs {
  m12 = 'M12',
  m18 = 'M18',
  mxFuel = 'MX FUEL',
}
