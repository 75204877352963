import React, { Fragment, FunctionComponent, useState } from 'react';
import { Dropdown } from '@components/shared';
import { Loader } from '@components/loaders';
import { States } from '@core/types';
import { ProjectEnums } from '@core/enums';
import classNames from 'classnames';
import Checkbox from '@components/shared/Checkbox';
import { t } from '@components/translations';
import { PDFSize, PDFType } from '@core/enums/project';

interface IProps {
  app: States.IAppState;
  project: States.IProjectState;
  onSelectedSettings: (
    cultureCode: string,
    size: ProjectEnums.PDFSize,
    type: ProjectEnums.PDFType,
    headerLayout: ProjectEnums.PDFHeaderLayout,
  ) => void;
  onSubmit: () => void;
  onDownload: () => void;
  setOrderDetails: (formData: Record<string, string> | null) => void;
}

const PdfDownloadSettings: FunctionComponent<IProps> = ({ app, project, onSelectedSettings, onDownload }) => {
  const [cultureCode, setCultureCode] = useState<string>('');
  const [pdfSize, setPdfSize] = useState<ProjectEnums.PDFSize>(ProjectEnums.PDFSize.A4);
  const [headerLayout, setHeaderLayout] = useState<ProjectEnums.PDFHeaderLayout>(ProjectEnums.PDFHeaderLayout.Normal);

  const handleSubmitInternal = () => {
    if (cultureCode === '') {
      return;
    }

    onSelectedSettings(cultureCode, pdfSize, PDFType.Download, headerLayout);
  };

  const renderGenerate = () => {
    const buttonStyles = classNames({
      btn: true,
      'btn--disabled': cultureCode === '',
    });

    const dropdownCultures = app.cultures
      .map(x => ({
        key: x.cultureCode,
        value: t(x.cultureName),
      }))
      .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

    return (
      <Fragment>
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Choose Your Language</h2>
            <p className="align--center">
              Select which language you’d like your fact tags to be generated in and click download to generate your PDF.
            </p>
            <Dropdown
              placeholder="Select Language..."
              onChange={(value: string) => setCultureCode(value)}
              value={cultureCode}
              values={dropdownCultures}
            />

            <Dropdown
              includeSelectedItem={true}
              onChange={(value: string) => setPdfSize(parseInt(value, 10) as ProjectEnums.PDFSize)}
              value={pdfSize.toString()}
              values={[
                {
                  key: ProjectEnums.PDFSize.A4.toString(),
                  value: 'A4 (4 x <strong>14.9 x 6.9cm)</strong>',
                },
                {
                  key: ProjectEnums.PDFSize.ReorderTags.toString(),
                  value: 'Reorder Tags',
                },
                {
                  key: ProjectEnums.PDFSize.ReorderTagsTiled.toString(),
                  value: 'Reorder Tags (Tiled)',
                },
                {
                  key: ProjectEnums.PDFSize.Flyer.toString(),
                  value: 'A4 Flyer',
                },
                {
                  key: ProjectEnums.PDFSize.JSSA4.toString(),
                  value: 'JSS A4',
                },
                {
                  key: ProjectEnums.PDFSize.JSSA4Double.toString(),
                  value: 'JSS A4 (Double)',
                },
                {
                  key: ProjectEnums.PDFSize.JSSA4Triple.toString(),
                  value: 'JSS A4 (Triple)',
                },
                {
                  key: ProjectEnums.PDFSize.JSS16x9.toString(),
                  value: 'JSS 16x9',
                },
              ]}
            />

            <Checkbox
              label="Export with Milwaukee&reg; Logo"
              value={headerLayout === ProjectEnums.PDFHeaderLayout.Normal}
              onClick={() => {
                if (headerLayout === ProjectEnums.PDFHeaderLayout.Normal) {
                  setHeaderLayout(ProjectEnums.PDFHeaderLayout.NoLogo);
                } else {
                  setHeaderLayout(ProjectEnums.PDFHeaderLayout.Normal);
                }
              }}
            />
          </div>
        </div>
        <button className={buttonStyles} onClick={handleSubmitInternal} type="button">
          Create PDF
        </button>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {project.downloadPDF.projectPdf === null && !project.downloadPDF.modal.isGenerating && renderGenerate()}

      {project.downloadPDF.modal.isGenerating && (
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Generating PDF</h2>
            <p className="align--center" dangerouslySetInnerHTML={{ __html: t('ftg.generatepdf.download') }}></p>
            <Loader />
          </div>
        </div>
      )}

      {project.downloadPDF.projectPdf && !project.downloadPDF.modal.isGenerating && (
        <Fragment>
          <div className="form__inner">
            <div className="form__fields">
              <h2 className="align--center">Download your PDF</h2>
              <p className="align--center">Click below to download your PDF</p>
            </div>
          </div>
          <button className="btn" onClick={onDownload}>
            Download
          </button>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PdfDownloadSettings;
